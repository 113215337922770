import { Add } from "./icons"

export default {
  components: {
    Add,
  },
  template: `<div class="d-flex align-items-center obituary-add-field">
    <div class="obituary-add-field-line flex-fill"></div>
    <add class="" />
  </div>`,
}
