import ObituaryEditor from "./editor"

export const setup = function () {
  const editorElem = document.getElementById("obituary-editor")

  if (!editorElem) return

  const editorProps = { ...editorElem.dataset } // pass data attributes as props
  editorProps.profileImageOptions = JSON.parse(editorProps.profileImageOptions) // pass nested objects as javascript objects
  editorProps.nameBoldStyle = editorProps.nameBoldStyle == "true" // bools are stored as string in DOMStringMap of dataset
  editorProps.deletedFields = JSON.parse(editorProps.deletedFields) // also need this array as JS object
  Vue.createApp(ObituaryEditor, editorProps).mount(editorElem)
}
