import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $('#obituary_free_entry_municipality_name, #obituary_entry_municipality_name, #obituary_pdf_entry_municipality_name').on('change', function() {
      if($(this).val() == '0') {
        $('.additional-municipality').removeClass('hide');
      } else {
        $('.additional-municipality').addClass('hide');
      }
    });
  }
}
