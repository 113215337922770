export const AutoHeightDirective = {
  mounted: function (el) {
    const minHeight = parseFloat(getComputedStyle(el).getPropertyValue("min-height"));
    const observe = function (element, event, handler) {
      element.addEventListener(event, handler, false);
    };

    let resize = () => {
      el.style.height = "auto";
      let border = el.style.borderTopWidth * 2;
      el.style.setProperty("height", ((el.scrollHeight < minHeight ? minHeight : el.scrollHeight) + border) + "px", "important");
    };

    // 0-timeout to get the already changed el
    let delayedResize = () => {
      window.setTimeout(resize, 0);
    };

    observe(el, "change", resize);
    observe(el, "cut", delayedResize);
    observe(el, "paste", delayedResize);
    observe(el, "drop", delayedResize);
    observe(el, "input", resize);

    resize();
  }
};
