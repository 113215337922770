import {
  Trash,
  HStart,
  HCenter,
  HEnd,
  VStart,
  VCenter,
  VEnd,
  Bold,
  AddImage
} from "./icons"

export default {
  components: {
    HStart,
    HCenter,
    HEnd,
    VStart,
    VCenter,
    VEnd,
    Bold,
    Trash,
    AddImage,
  },
  template: `
  <div class="position-relative">
    <div class="obituary-editor-toolbar-container">
      <div class="obituary-editor-toolbar">
        <a href="#" v-if="vAlign" class="obituary-editor-toolbar-button" :class="{'is-selected': vAlign === 'start'}" @click.prevent.stop="$emit('update:vAlign', 'start')" title="Links ausrichten"><v-start /></a>
        <a href="#" v-if="vAlign" class="obituary-editor-toolbar-button" :class="{'is-selected': vAlign === 'center'}" @click.prevent.stop="$emit('update:vAlign', 'center')" title="Zentriert ausrichten><v-center /></a>
        <a href="#" v-if="vAlign" class="obituary-editor-toolbar-button" :class="{'is-selected': vAlign === 'end'}" @click.prevent.stop="$emit('update:vAlign', 'end')" title="Rechts ausrichten"><v-end /></a>

        <a href="#" class="obituary-editor-toolbar-button" :class="{'is-selected': align === 'start'}" @click.prevent.stop="$emit('update:align', 'start')" title="Oben ausrichten"><h-start /></a>
        <a href="#" class="obituary-editor-toolbar-button" :class="{'is-selected': align === 'center'}" @click.prevent.stop="$emit('update:align', 'center')" title="Mittig ausrichten"><h-center /></a>
        <a href="#" class="obituary-editor-toolbar-button" :class="{'is-selected': align === 'end'}" @click.prevent.stop="$emit('update:align', 'end')" title="Unten ausrichten"><h-end /></a>


        <a href="#" class="obituary-editor-toolbar-button" :class="{'is-selected': bold}" @click.prevent.stop="$emit('update:bold', !bold)" v-if="bold !== null" title="Fett formattieren"><bold /></a>
        <a href="#" class="obituary-editor-toolbar-button" @click.prevent.stop="$emit('add-image')" v-if="withImage" title="Bild neben Text anzeigen"><add-image /></a>
        <a href="#" class="obituary-editor-toolbar-button trash" @click.prevent.stop="$emit('delete')" v-if="trashable" title="Feld löschen"><trash /></a>

        <a href="#" class="obituary-editor-toolbar-button obituary-editor-toolbar-button--text" v-if="exampleLabel" @click.prevent.stop="$emit('exampleClicked')" :title="exampleLabel">{{ exampleLabel }}</a>

      </div>
    </div>
  </div>
  `,
  props: {
    align: { type: String, required: true },
    vAlign: { type: String, required: false, default: null },
    bold: { type: Boolean, required: false, default: null },
    trashable: { type: Boolean, required: false, default: true },
    exampleLabel: { type: String, required: false, default: "Beispiele" },
    withImage: { type: Boolean, required: false, default: false },
  },
  emits: ["update:align", "update:vAlign", "update:bold", "exampleClicked", "delete", "add-image"]
}
