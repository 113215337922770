import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $('.obituary-publication-type input[type=radio]').on('change', function() {
      $('.additional-content').addClass('hide');
      $(this).siblings('.additional-content').removeClass('hide');
    });
  }
}
