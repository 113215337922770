// Directive to handle click events outside of an element
// Usage:
// <div v-click-outside="eventHandler">Some content</div>

export const ClickOutsideDirective = {
  // `binding` must be a function as shown in the usage example
  mounted(el, binding) {
    const callback = binding.value
    const bubble = binding.modifiers.bubble

    // Define the callback handler and cache it on the element so it can be unbound
    // Only trigger the callback when the clicked element is not contained within `el`
    const handler = e => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        callback(e);
      }
    }

    el.__clickOutsideHandler__ = handler;

    setTimeout(() => {
      document.addEventListener('click', handler)
    })
  },

  beforeUnmount: function (el) {
    document.removeEventListener('click', el.__clickOutsideHandler__);
    el.__clickOutsideHandler__ = null
  },
}
