// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import jquery from "jquery"

window.jQuery = jquery
window.$ = jquery

require("bootstrap-datepicker/js/locales/bootstrap-datepicker.de.js")

import * as bootstrap from "bootstrap"
import * as datepicker from "bootstrap-datepicker"
import { setup as setupObituary } from "./obituary"
import "./controllers"

document.addEventListener("DOMContentLoaded", function () {
  setupObituary();
});
