import { DirectUpload } from "@rails/activestorage";
import Toolbar from "./components/toolbar"
import ImageToolbar from "./components/image-toolbar"
import AddField from "./components/add-field";
import { AutoHeightDirective } from "./directives/autoheight"
import { AutoWidthDirective } from "./directives/autowidth"
import { ClickOutsideDirective } from "./directives/click-outside"
import dialogPolyfill from 'dialog-polyfill'

const previewUrl = (signedId) => `/rails/active_storage/blobs/${signedId}/image`
const appendHiddenImageField = (value, name = "obituary_entry[image]") => {
  // Override the submitted "image" with the attachment ID
  // Rails automatically knows what to do with it and
  // will attach the image onto the Obituary::Entry
  const hiddenField = document.createElement("input")
  hiddenField.setAttribute("type", "hidden");
  hiddenField.setAttribute("value", value);
  hiddenField.name = name
  document.forms["obituary-editor"].appendChild(hiddenField)
}

export default {
  components: {
    Toolbar,
    ImageToolbar,
    AddField,
  },
  directives: {
    autoheight: AutoHeightDirective,
    autowidth: AutoWidthDirective,
    clickOutside: ClickOutsideDirective,
  },
  props: [
    "locationAlignment",
    "backgroundImage",
    "imagePlaceholder",
    "image",
    "profileImageAlignment",
    "profileImageOptions",
    "profileImage",
    "funeralSayingAlignment",
    "funeralSayingVerticalAlignment",
    "introductionAlignment",
    "predeceasedNameAlignment",
    "bornAlignment",
    "mainTextAlignment",
    "textColumnLeftAlignment",
    "textColumnRightAlignment",
    "additionalTextAlignment",
    "additionalTextSmallAlignment",
    "fontStyle",
    "borderStyle",
    "nameBoldStyle",
    "deletedFields",
  ],
  data() {
    return {
      activeToolbar: null,
      gotoNextStepDisabled: false,
      // NOTE: We can't (and shouldn't) directly mutate props
      // since they initially come from the server
      // we must create new data values here
      alignLocation: this.locationAlignment,
      alignImage: this.profileImageAlignment,
      selectedProfileImage: this.profileImage,
      alignFuneralSaying: this.funeralSayingAlignment,
      vAlignFuneralSaying: this.funeralSayingVerticalAlignment,
      alignIntroduction: this.introductionAlignment,
      alignNames: this.predeceasedNameAlignment,
      boldNames: this.nameBoldStyle,
      alignBirthdate: this.bornAlignment,
      alignMainText: this.mainTextAlignment,
      alignLeftColumnText: this.textColumnLeftAlignment,
      alignRightColumnText: this.textColumnRightAlignment,
      alignAdditionalText: this.additionalTextAlignment,
      alignAdditionalSmallText: this.additionalTextSmallAlignment,
      fontStyleOption: this.fontStyle,
      borderStyleOption: this.borderStyle,
      backgroundImageOption: this.backgroundImage,
      displayedImageUrl: this.image,
      auxiliaryLines: false,
      trashedFields: this.deletedFields,
    }
  },
  methods: {
    changeFont(event) {
      this.fontStyleOption = event.target.value
    },
    changeBorder(event) {
      this.borderStyleOption = event.target.value
    },
    changeBackground(event) {
      this.backgroundImageOption = event.target.value
    },
    mapToTextAlign(alignment) {
      return { "start": "left", "center": "center", "end": "right" }[alignment]
    },
    resetActiveToolbar(value) {
      // Only reset the when the given toolbar is still active
      // Otherwise it may reset a toolbar that has been given focus
      if (value === this.activeToolbar) {
        this.activeToolbar = null
      }
    },
    setExample(inputName, value) {
      const inputNodes = document.getElementsByName(inputName)
      const input = inputNodes.length > 0 && inputNodes[0]

      if (input) {
        input.value = value
        input.dispatchEvent(new Event('input'))
      }

      this.closeDialog()
    },
    showDialog(dialogId) {
      var dialog = document.getElementById(dialogId)
      dialogPolyfill.registerDialog(dialog)
      dialog.showModal();
    },
    closeDialog() {
      const openDialog = document.querySelector("dialog[open]")

      if (openDialog) {
        openDialog.close()
      }
    },
    selectImage(event) {
      const elem = this.$refs.imageUploadField
      const uploadUrl = elem.dataset.directUploadUrl
      const fileSelection = event.target.files
      const selectedFile = fileSelection[0]

      if (!selectedFile) return

      this.selectedProfileImage = null

      const upload = new DirectUpload(selectedFile, uploadUrl)
      this.gotoNextStepDisabled = true

      upload.create((error, blob) => {
        if (error) {
          this.gotoNextStepDisabled = false
        } else {
          // Capture the attachment ID of the uploaded file
          let signedId = blob.signed_id;
          this.displayedImageUrl = previewUrl(signedId)
          appendHiddenImageField(signedId)
          this.gotoNextStepDisabled = false
        }
      })
    },
    deleteImage(event) {
      this.selectedProfileImage = null
      this.displayedImageUrl = null
      this.toggleTrashedField('image')
    },
    selecteSujet(event) {
      this.selectedProfileImage = event.target.value
      this.displayedImageUrl = null
      this.closeDialog()
    },
    toggleTrashedField(fieldName) {
      const idx = this.trashedFields.indexOf(fieldName)

      if (idx !== -1) {
        this.trashedFields.splice(idx, 1);
      } else {
        this.trashedFields.push(fieldName);
      }
    },
    isTrashedField(fieldName) {
      return this.trashedFields.indexOf(fieldName) !== -1
    }
  },
  computed: {
    styleClasses() {
      return {
        [`obituary-editor-font--${this.fontStyleOption}`]: true,
        [`obituary-editor-frame--${this.borderStyleOption}`]: true,
        [`obituary-editor-frame--hide-auxiliary-lines`]: this.auxiliaryLines,
        [`obituary-background-image--${this.backgroundImageOption}`]: true,
      }
    },
    fontName() {
      return {
        font1: "Open+Sans",
        font2: "Roboto",
        font3: "Libre+Caslon+Text",
      }[this.fontStyleOption]
    },
    currentImageSrc() {
      return this.displayedImageUrl || (this.selectedProfileImage && this.profileImageOptions[this.selectedProfileImage]) || this.imagePlaceholder
    }
  }
}
