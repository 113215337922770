export const AutoWidthDirective = {
  mounted: function (el) {
    const fakeElem = document.createElement('div')

    // Hide the fake input
    // It is used off-screen to measure the actual width
    fakeElem.style.position = 'absolute'
    fakeElem.style.top = '0'
    fakeElem.style.left = '-9999px'
    fakeElem.style.overflow = 'hidden'
    fakeElem.style.visibility = 'hidden'
    fakeElem.style.whiteSpace = 'nowrap'
    fakeElem.style.height = '0'

    const observe = function (element, event, handler) {
      element.addEventListener(event, handler, false)
    };

    let resize = () => {
      const styles = window.getComputedStyle(el)

      // Copy font styles from the textbox
      fakeElem.style.fontFamily = styles.fontFamily
      fakeElem.style.fontSize = styles.fontSize
      fakeElem.style.fontStyle = styles.fontStyle
      fakeElem.style.fontWeight = styles.fontWeight
      fakeElem.style.letterSpacing = styles.letterSpacing
      fakeElem.style.textTransform = styles.textTransform

      fakeElem.style.borderLeftWidth = styles.borderLeftWidth
      fakeElem.style.borderRightWidth = styles.borderRightWidth
      fakeElem.style.paddingLeft = styles.paddingLeft
      fakeElem.style.paddingRight = styles.paddingRight

      // Append the fake element to `body` so its rendered and can be measured
      // appendChild is idempotent when called with the same reference
      document.body.appendChild(fakeElem)

      // Update the width of a text input based on the
      // measurements of it's displayed text (either placeholder value or actual value)
      // by measuring the effective width on an offscreen input element
      const string = el.value || el.getAttribute('placeholder') || ''
      fakeElem.innerHTML = string.replace(/\s/g, '&' + 'nbsp;')

      const fakeElemStyles = window.getComputedStyle(fakeElem)
      const margin = 14
      el.style.width = `${parseInt(fakeElemStyles.width) + margin}px`;
    };

    // 0-timeout to get the already changed el
    let delayedResize = (timeout = 0) => {
      window.setTimeout(resize, timeout)
    }

    observe(el, "change", resize)
    observe(el, "cut", () => delayedResize())
    observe(el, "paste", () => delayedResize())
    observe(el, "drop", () => delayedResize())
    observe(el, "input", resize)

    resize()

    // NOTE: added this to fire when custom fonts are loaded
    // because the input width may need to be wider depending on the font cut
    window.addEventListener('load', () => delayedResize(50))
  }
};
