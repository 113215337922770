import { Trash, HStart, HEnd } from "./icons"

export default {
  components: {
    HStart,
    HEnd,
    Trash,
  },
  template: `
  <div class="position-absolute w-100">
    <div class="obituary-editor-toolbar-container obituary-editor-toolbar-container--flush">
      <div class="obituary-editor-toolbar">
        <a href="#" class="obituary-editor-toolbar-button" :class="{'is-selected': align === 'start'}" @click.prevent.stop="$emit('update:align', 'start')" title="Bild links anzeigen"><h-start /></a>
        <a href="#" class="obituary-editor-toolbar-button" :class="{'is-selected': align === 'end'}" @click.prevent.stop="$emit('update:align', 'end')" title="Bild rechts anzeigen"><h-end /></a>
        <a href="#" class="obituary-editor-toolbar-button trash" @click.prevent.stop="$emit('delete', 'end')" title="Bild löschen"><trash /></a>
        <span class="obituary-editor-toolbar-button obituary-editor-toolbar-button--inactive flex-fill"></span>
      </div>
      <div class="obituary-editor-toolbar">
        <a href="#" class="obituary-editor-toolbar-button obituary-editor-toolbar-button--text flex-fill" @click.prevent.stop="$emit('uploadImage')" title="Porträt hochladen">Porträt/Bild hochladen</a>
      </div>
      <div class="obituary-editor-toolbar">
        <a href="#" class="obituary-editor-toolbar-button obituary-editor-toolbar-button--text flex-fill" @click.prevent.stop="$emit('openSujets')" title="Sujet auswählen">Sujet auswählen</a>
      </div>
    </div>
  </div>
  `,
  props: {
    align: { type: String, required: true },
  },
  emits: ["openSujets", "uploadImage", "delete", "update:align"]
}
